import request from '@/utils/request';

export function listAllJobs() {
  return request({
    url: '/clientJobs',
    method: 'get'
  });
}

export function submitWorkExperience(data) {
  return request({
    url: '/user/account/workexperience',
    method: 'post',
    data: data
  });
}

export function submitWorkPreference(data) {
  return request({
    url: '/user/account/workpreference',
    method: 'post',
    data: data
  });
}

export function submitAccountSetup(data) {
  return request({
    url: '/user/account/accountsetup',
    method: 'post',
    data: data
  });
}
