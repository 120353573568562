<script setup>
// import { RouterLink } from 'vue-router'
</script>
<template>
    <div class="tw-fixed tw-z-10 tw-top-0 tw-w-full">
        <nav class="tw-navbar tw-bg-fastwira-default-bg tw-border-gray-200 tw-sticky tw-w-full tw-top-0 tw-z-30">
            <div class="tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-mx-auto tw-max-w-screen-xl tw-p-4 tw-px-0 tw-md:tw-px-0 tw-md:tw-py-2">
                <a href="/" class="tw-flex tw-items-center tw-navbar-brand">
                    <img src="/images/logo.png" class="tw-h-7 tw-mr-3" alt="Fastwira" />
                </a>
                <button data-collapse-toggle="mega-menu-full" type="button"
                    class="mobLoginBtn tw-inline-flex tw-items-center tw-p-2 tw-ml-1 tw-text-sm tw-text-gray-500 tw-rounded-lg md:tw-hidden hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-200"
                    aria-controls="mega-menu-full" aria-expanded="false" @click="toggleMenu">
                    <span class="tw-sr-only">Open main menu</span>
                    <svg class="tw-w-6 tw-h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                            clip-rule="evenodd"></path>
                    </svg>
                </button>
                <div id="mega-menu-full" class="tw-items-center tw-justify-between tw-hidden tw-w-full tw-p-4 md:tw-p-0 md:tw-flex md:tw-w-auto">
                    <ul class="tw-flex tw-flex-col tw-mt-4 md:tw-flex-row md:tw-space-x-8 md:tw-mt-0 md:tw-font-medium md:tw-border-0">
                        <li class="nav-item active">
                            <router-link to="/" class="tw-block tw-py-2 tw-pl-3 tw-pr-4 tw-text-white tw-rounded">
                                Home
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/jobs" class="tw-block tw-py-2 tw-pl-3 tw-pr-4 tw-text-white tw-rounded">
                                Find Jobs
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/hire" class="tw-block tw-py-2 tw-pl-3 tw-pr-4 tw-text-white tw-rounded">
                                Hire Freelancer
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/help" class="tw-block tw-py-2 tw-pl-3 tw-pr-4 tw-text-white tw-rounded">
                                Help
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="tw-rightnav text-white tw-hidden md:tw-block" v-if="!userInfo">
                    <ul class="tw-flex">
                        <router-link to="/login" class="loginBtnHome">
                            Login
                        </router-link>
                        <router-link to="/register" class="registerBtnHome">
                            Get Started
                        </router-link>
                    </ul>
                </div>
                <UserDropdown v-else class="text-white userDropDown"/>
            </div>
        </nav>
        <div class="loginSignUp md:tw-hidden" v-if="!userInfo">
            <div class="row h-30">
                <div class="col p-0 loginMobile">
                    <router-link to="/login" class="">
                        Login
                    </router-link>
                </div>
                <div class="col p-0 registerMobile">
                    <router-link to="/register" class="">
                        Register
                    </router-link>
                </div>
            </div> 
        </div>
    </div>

</template>

<script>
import { isLogged } from '@/utils/auth';
import UserDropdown from '@/components/UserDropdown.vue';

export default {
    components: {
        UserDropdown
    },
    data() {
        return {
            showLoginModal: false,
        };
    },
    methods: {
        toggleMenu() {
            const screenWidth = window.innerWidth;

            if (screenWidth <= 768) { // Adjust the screen width condition as per your requirement
                const containerElement = document.getElementById('mega-menu-full');
                const computedStyle = window.getComputedStyle(containerElement);

                if (computedStyle.display === 'none') {
                    containerElement.style.display = 'block';
                } else {
                    containerElement.style.display = 'none';
                }
            } else {
                const containerElement = document.getElementById('mega-menu-full');
                containerElement.style.display = 'block';
            }
        }
    },
    computed: {
        userInfo() {
            // check if logged in
            if(isLogged()) {
                return this.$store.getters.user;
            }
            return false;
        }
    },
}
</script>

<style></style>