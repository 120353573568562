<template>
  <div class="d-flex justify-content-center align-items-center">
    <li class="nav-item me-2 navbar-end-elem">
      <div class="d-flex align-items-center justify-content-center">
        <div class="userIndicator">
          <span>{{ getInitials }}</span>
          <img src="/images/icons/online-indicator-icon.svg" alt="" class="online-indicator">
        </div>
      </div>
    </li>
    <li class="nav-item dropdown mr-5 float-end">
        <a class="usernameLink nav-link dropdown-toggle font-weight-400 hover:tw-text-white" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        {{ userInfo.full_name }}
        </a>
        <ul class="dropdown-menu profileDropdown">
          <li>
            <router-link to="/profile" class="dropdown-item font-weight-400">
                View Profile
            </router-link>
          </li>
          <li><a class="dropdown-item font-weight-400" href="#" @click="userLogout">Log out</a></li>
        </ul>
    </li>
  </div>
</template>

<script>
import { getInitials } from '@/filters';

export default {
  data() {
    return {
      userInfo : this.$store.getters.user,
      initialsString: '',
    }
  },
  computed: {
    getInitials() {
      return getInitials(this.userInfo.full_name);
    }
  },
  methods: {
    async userLogout() {
      this.$router.push({ path: '/' });
      this.$store.dispatch('user/logout')
      .then(() => {
        this.$router.push({ path: '/' }) //go to home.vue
      });
      window.location.reload();
    },
  }
}
</script>

<style lang="scss" scoped>

</style>