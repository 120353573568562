import { getToken, setLogged } from '@/utils/auth';
import axios from "axios";

// Create axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000, // Request timeout
});

// Request intercepter
service.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + getToken(); // Set JWT token
    }
    return config;
  },

  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// // response pre-processing
service.interceptors.response.use(
  response => {
    if (response.data.status === 'success') {
      setLogged(response.data.token);
    } else if (response.data.status === 'error') {
      response.data.message = response.message;
    }

    return response.data;
  },
  error => {
    // let message = error.message;
    if (error.status === 'error') {
      // message = error.message;
    }
    // if (error.response.data.status === 'error') {
    //   message = error.response.data.message;
    // }
    console.log(error);
    // Message({
    //   message: message,
    //   type: 'error',
    //   duration: 5 * 1000,
    // });
    // return Promise.reject(error);
    return error;
  }
);

export default service;
