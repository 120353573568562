<template>
  <div class="font-roboto">
    <HeaderComponent v-if="isWebLandingPage" />
      <RouterView />
    <FooterComponent v-if="isWebLandingPage" />
    <BackToTop />
  </div>
</template>

<script>
  import { RouterView } from 'vue-router';
  import HeaderComponent from '@/sections/HeaderGeneral.vue';
  import FooterComponent from '@/sections/FooterGeneral.vue';
  import BackToTop from '@/components/BackToTop/index';

  export default {
    name: 'App',
    components: {
      RouterView,
      HeaderComponent,
      FooterComponent,
      BackToTop
    },
    computed: {
      isWebLandingPage() {
        const isWebLandingPage = this.$route.meta.module === 'weblanding';
        isWebLandingPage ? document.documentElement.style.fontSize = '16px' : document.documentElement.style.fontSize = '62.5%';
        return isWebLandingPage;
      }
    },
  };
</script>

<style scoped>
</style>
