import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store/index';
import { FontAwesomeIcon } from './fontawesome';
import './assets/css/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert';

const app = createApp(App);
app.config.globalProperties.$swal = Swal;
app.use(router);
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
