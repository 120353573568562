/* eslint-disable */

import { submitWorkExperience, submitWorkPreference, submitAccountSetup } from '@/api/jobs';

const state = {
  work: {}
};

const mutations = {
  SET_WORK_EXPERIENCE: (state, work) => {
    state.work.current_usertitle_years_of_experience = work.years,
    state.work.expertise = work.expertise,
    state.work.overal_workexperience_category = work.overalYears,
    state.work.job_title = work.job_title,
    state.work.company = work.company,
    state.work.country_id = work.country_id,
    state.work.city = work.city,
    state.work.start_date = work.from_date,
    state.work.end_date = work.to_date,
    state.work.description = work.desc
  },
};

const actions = {
  // submit work experience details
  submitWorkExperience({ commit }, work) {
    console.log(work);
    return new Promise((resolve, reject) => {
      submitWorkExperience(work)
        .then(response => {
          console.log(response);
          resolve();
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  // submit work preference details
  submitWorkPreference({ commit }, prefs) {
    console.log(prefs);
    return new Promise((resolve, reject) => {
      submitWorkPreference(prefs)
        .then(response => {
          console.log(response);
          resolve();
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  // submit work preference details
  submitAccountSetup({ commit }, account) {
    console.log(account);
    return new Promise((resolve, reject) => {
      submitAccountSetup(account)
        .then(response => {
          console.log(response);
          resolve();
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
