import Cookies from 'js-cookie';
import store from '@/store';

const TokenKey = true;

export function isLogged() {
  return !!Cookies.get(TokenKey);
}

export function getToken() {
  return store.getters.token;
}

export function setLogged(isLogged) {
  return Cookies.set(TokenKey, isLogged);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
