import { createRouter, createWebHistory } from 'vue-router';
import errorRoutes from './modules/error';
import registerRoutes from './modules/register';
import { isLogged } from '@/utils/auth';

export const constantRoutes = [
  {
    path: '/',
    component: () => import('@/views/weblanding/index'),
    name: 'Homepage',
    meta: {
      module: 'weblanding',
    }
  },
  {
    path: '/help',
    component: () => import('@/views/weblanding/pages/helpContact'),
    name: 'Find Help',
    meta: {
      module: 'weblanding',
    }
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
  },
  {
    path: '/404',
    redirect: { name: 'Page404' },
    component: () => import('@/views/error-page/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true,
  },
  {
    path: '/client/job',
    component: () => import('@/views/jobs/JobPost'),
    name: 'PostJob'
  },
  {
    path: '/client/company',
    component: () => import('@/views/register/client/CompanyDetails'),
    name: 'CompanyDetails'
  },
  {
    path: '/client/company_contacts',
    component: () => import('@/views/register/client/CompanyContact'),
    name: 'CompanyContact',
  },
  {
    path: '/client',
    component: () => import('@/views/ClientLanding'),
    name: 'Client View',
    hidden: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/profile',
    component: () => import('@/views/users/UserProfile'),
    name: 'UserProfile',
    hidden: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/hire',
    component: () => import('@/views/jobs/JobPost'),
    name: 'Hire Freelancers',
    hidden: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/jobs',
    component: () => import('@/views/jobs/AllJobs'),
    name: 'Freelancer',
    hidden: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/job/:id',
    component: () => import('@/views/jobs/JobDetails'),
    hidden: true,
    meta: {
      requiresAuth: true,
    }
  },
  registerRoutes,
  errorRoutes,
  { path: "/:catchAll(.*)", redirect: '/404', hidden: true },
];

const createRoute = () => new createRouter({
  mode: 'history', // require service support
  history: createWebHistory(),
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: constantRoutes,
});

const router = createRoute();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

router.beforeResolve((to, from, next) => {
  console.log('outside');
  console.log(to);
  console.log(to.meta?.requiresAuth);
  if (to.matched.some((record) => record.meta?.requiresAuth === true)) {
    console.log('inside');
    if (!isLogged()) {
      // Check if the current route is already the login page
      if (to.path !== '/login') {
        next({ path: '/login' }); // Redirect to the login page
      } else {
        next(); // Allow access to the login page
      }
    } else {
      next(); // Proceed to the protected route
    }
  } else {
    next(); // Allow access to non-protected routes
  }
});

export default router;